export const UploadLimit = 10 * 1024 * 1024

export const isSSR = typeof window === "undefined"
export const isMacLike = !isSSR && /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform)
export const isIOS = !isSSR && /(iPhone|iPod|iPad)/i.test(navigator.platform)

export const isProduction = process.env.NODE_ENV === 'production'
export const isDevelopment = !isProduction
export const isStagingOrDevelopment = isDevelopment || (!isSSR && window.location.hostname.includes('glokurs.com'))

export const PollInterval = 30000
